import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';

import config from '../../config';

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                keywords
                author
            }
        }
    }
`;

const Error404Page = () => (
    <StaticQuery
        query={query}
        render={data => {
            const {
                title,
                description,
                author,
                keywords,
            } = data.site.siteMetadata;
            return (
                <Layout
                    title={title}
                    description={description}
                    author={author}
                    keywords={keywords}
                >
                    <section id="banner">
                        <div className="inner">
                            <h2>{config.heading}</h2>
                            <p>
                                Désolé, cette page n&apos;existe pas 🙈
                                <br />
                                <br />
                                Revenir sur <a href="/">www.rock-france.com</a>
                            </p>
                        </div>
                    </section>
                </Layout>
            );
        }}
    />
);

export default Error404Page;
